.ReactTable {
  display: block;
}
 .ReactTable .rt-thead.-filters input{
  background-color: lightgrey;
  background-image: url("./svg/magGlassOpenSource_grey.svg"); 
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 30px
} 
.button.is-info.is-fullwidth {
  text-decoration: underline
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

.header li {
  float: left;
}

.header li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.header li a:hover {
  background-color: #111;
}

.center {
  text-align: center;
}
